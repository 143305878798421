// regionType 1 上海  2 广州  3 南京  4  肇庆
// 社会招聘
export const data = [
  {
    regionType:1,
    banner: {
      pc: '/images/shanghai/1.jpg',
      msite: '/images/shanghai/1_1.jpg'
    },
    jobs: [
      {
        id: 'shanghai-job-1',
        title: '资深网站运营',
        image: '/images/shanghai/2.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-1-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、根据网站定位、业务目标，制定所负责站点的网站运营计划及策略；',
              '2、从创意策划、流量引入、数据效果等多角度考虑，策划网站运营活动；',
              '3、与平面设计师对接，按时发布和上线活动；',
              '4、追踪活动数据效果，以数据为导向及时调整并持续优化活动方案；',
              '5、关注市场行情及竞品动态，针对站点运营及销售提出合理化建议；',
              '6、领导布置的其他临时性任务。',
            ]
          },
          {
            id: 'shanghai-job-1-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、本科及以上学历，CET-4，市场营销/工商管理/语言专业优先，有海外留学经验或对海外市场了解者优先；',
              '2、2年及以上跨境电商网站运营经验，对快时尚行业感兴趣，有带团队经验者优先；',
              '3、数据敏感度高，能熟练使用Excel等办公软件，有一定数据分析能力，自我驱动力强，有较强的主动学习意识；',
              '4、良好的沟通表达能力，细心踏实有责任心。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-2',
        title: '用户运营专员',
        image: '/images/shanghai/8.jpg',
        regionType:1,
        time:"2022-06-06",
        isHot:false,
        descriptions: [
          {
            id: 'shanghai-job-2-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、根据市场特点及用户群体特征，负责EDM及PUSH的整体运营规划及落地；',
              '2、针对不同客户群体，制定个性化内容，开发潜在用户，激活沉睡用户，维护活跃用户；',
              '3、了解点击率、打开率相关指标，跟踪EDM及PUSH发送效果，以数据为导向，持续优化和调整用户运营方案；',
              '4、关注竞品、行业及市场动态，发掘用户增长点，不断优化用户运营策略，提高转化率、留存率、活跃度，提升用户粘性。',
            ]
          },
          {
            id: 'shanghai-job-2-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、本科及以上学历，CET-6及以上，经验丰富者可放宽至CET-4，市场营销/工商管理/语言专业优先，有海外留学经验或对海外市场了解者优先；',
              '2、接触过EDM，有用户运营/会员营销相关经验，有会员体系搭建经验者优先，有第三方平台操作经验或资源者优先；',
              '3、数据敏感度高，能熟练使用Excel等办公软件，有一定数据分析能力，自我驱动力强，有较强的主动学习意识；',
              '4、良好的沟通表达能力，细心踏实有责任心。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-3',
        title: '高级广告优化师',
        image: '/images/shanghai/10.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-3-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、管理和优化Facebook、Google推广账户；',
              '2、根据产品推广需求，制定科学合理的推广方案，并能够顺利实施；',
              '3、通过具体推广数据，分析市场需求，制定优化方案， 以严谨有效的广告投放分析思路进行管理优化；',
              '4、关注并学习研究有助于广告投放的相关功能或产品，评估并选择适合于公司产品推广的新功能或产品，开展投放工作。',
            ]
          },
          {
            id: 'shanghai-job-3-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、统招211、985本科及以上学历，CET-6及以上，英语/市场营销/电子商务/广告等相关专业；',
              '2、有一年以上跨境电商广告投放或优化经验，市场敏与锐度良好、数据敏感度高；',
              '3、有良好的逻辑分析能力、快速学习能力和团队协作精神；',
              '4、热爱互联网营销，对工作有激情，有很好的抗压能力和问题解决能力。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-4',
        title: '数据分析师',
        image: '/images/shanghai/10.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-4-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、负责对平台的日活、用户行为、销量、跳出率、转化率等做出专业的数据分析；',
              '2、负责数据质量监控，密切关注各项数据变化，针对异常情况进行预警，及时发现问题、提出问题并输出分析报告，给予相应部门数据支持和优化建议；',
              '3、配合产品、运营的需求，对用户行为数据进行数据挖掘、深度分析以及形成分析报告；',
              '4、通过数据洞察和分析，提供广告营销、产品运营等方面的分析思路和指标搭建看板。',
            ]
          },
          {
            id: 'shanghai-job-4-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、统招本科及以上学历；',
              '2、丰富的电商行业数据分析、用户分析和数据产品经验；',
              '3、具有较强的数据和业务的敏感性，具备良好的逻辑分析能力，能够系统性分析问题，同时具有较好的分析总结能力和数据报告呈现能力；',
              '4、熟练掌握SQL/Tableau/Excel或其他类似工具（Python加分项）；',
              '5、工作细心，表达能力强。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-5',
        title: '财务经理',
        image: '/images/shanghai/7.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-5-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、负责公司跨境电商账务处理，出具电商运营财务报表并做好流水汇集，正确、及时、完整地进出账； ',
              '2、熟练掌握出口退税、跨境收付汇(款) 、第三方支付工具资金核算等相关工作，做好电商平台各项收付费核算；',
              '3、负责境外关联公司的合规和账务处理；',
              '4、负责对公司跨境电商业务财务状况和经营成果进行分析，对资金运用、现金流量、财务成本等进行专题分析，并向上级领导提交相关财务分析报告； ',
              '5、负责制定税务筹划方案，指导落实税务缴纳情况，依据国家及关联公司所在国税法、会计准则及相关法律法规，规范企业税筹建设及规避税务风险；',
              '6、结合公司战略规划及经营发展，建立科学、系统符合本企业实际情况的财务核算体系和财务监控体系，进行有效的内部控制管理。',
            ]
          },
          {
            id: 'shanghai-job-5-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、本科及以上学历，会计、财务管理相关专业，八年以上财务工作经验，有CPAUS GAAP等相关资格证书等优先，具备2年以上跨境电商企业或四大会计事务所工作经验者优先; 熟悉中国大陆及中国香港或新加坡会计准则，有电商平台财务流程、财务分析、预算管理、财务报告等相关经历优先；熟悉欧美财务条款优先； ',
              '2、熟悉国家相关财政法律法规和税收政策，具有全面的财务知识体系，精通财务、税收、运营分析、成本控制;具有很强的财务管理能力、判断决策能力、应变能力、组织协调能力、文字表达能力;能够熟练运用SAP等财务软件;',
              '3、英语交流无障碍，熟练处理各种英文财务会计文件及其他法律合同等;能够承担较强的工作压力和工作强度，具备驾驭跨国背景下处理复杂问题的能力。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-6',
        title: '高级财务专员',
        image: '/images/shanghai/7.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-6-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、负责公司跨境电商平台业务的费用审核，做好流水汇集； ',
              '2、负责物流等费用的归集和审核，每月对账； ',
              '3、编制财务资料和归档管理并协助编制电商业务预算，每月对比预算和实际执行情况，及时反馈异常；',
              '4、确保资金的有效管理，加速资金周转，考核资金使用效果，规避资金和债务风险； ',
              '5、负责公司跨境电商账务处理，出具电商运营财务报表并做好流水汇集，正确、及时、完整地进出账； ',
              '6、熟练掌握出口退税、跨境收付汇(款) 、第三方支付工具资金核算等相关工作，做好电商平台各项收付费核算；',
              '7、负责境外关联公司的合规和账务处理。',
            ]
          },
          {
            id: 'shanghai-job-6-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、统招211、985本科及以上学历，会计、财务管理等相关专业；',
              '2、2年以上相关工作经验，有外资所或者外企工作经验优先；',
              '3、能熟练操作财务ERP系统，有电商财务经验优先；',
              '4、英语交流无障碍，能处理各种英文财务会计文件及其他法律合同等；',
              '5、工作仔细认真，责任心强，能积极承担领导分配的工作。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-7',
        title: '总账会计',
        image: '/images/shanghai/7.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-7-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、负责物流供应商每月账单核对; ',
              '2、负责与客户对接，每月核对往来账，与支付公司对接前期的KYC资料准备工作；',
              '3、负责公司开票、发票审核及规范公司发票管理：',
              '4、负责与海外公司境内代理、秘书公司的对接；',
              '5、负责凭证、账簿及其他会计资料的收集、装订、归档工作; ',
              '6、负责网银制单、银行对账及相关外汇银行入账；',
              '7、定期对固定资产、低值易耗等财产物品进行盘点，做到账实相符，发现问题及时处理，准确计提折旧费; ',
              '8、协调对外审计，提供所需财会资料；',
              '9、定期组织检查会计政策执行情况，严控操作风险，解决存在问题; ',
              '10、领导安排的其他临时事务。',
            ]
          },
          {
            id: 'shanghai-job-7-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、财务、会计专业本科以上学历，须持有会计上岗证，有初级会计职称优先，必须有会计相关工作经验，有总账会计经验优先；',
              '2、了解会计报表的处理，会计法规和税法；',
              '3、能够熟练使用Excel表及运用各类函数；',
              '4、具有良好的学习能力、独立工作能力和财务分析能力；',
              '5、工作细致，责任感强，良好的沟通能力、团队精神。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-8',
        title: '风控支付专员',
        image: '/images/shanghai/3.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-8-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、审核平台网站订单并判断订单风险，邮件客户确认订单相关信息；',
              '2、判断客户提供的证件信息真实性，有无欺诈行为；',
              '3、根据欺诈类型订单特征优化风控规则，提高拦截率及确认率；',
              '4、拒付订单申诉，在有效期内及时申诉客户已开的拒付订单。',
            ]
          },
          {
            id: 'shanghai-job-8-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、统招211、985本科及以上学历，大学英语6级及以上水平；',
              '2、英语读写能力：可以书面无障碍交流；',
              '3、热爱互联网工作，一年以上电商风控、合规、财务岗位经验优先； ',
              '4、良好的学习与抗压能力，能自我驱动，有强烈的责任感。',
            ]
          }
        ]
      },
    ]
  },
  {
    regionType:2,
    banner: {
      pc: '/images/guangzhou/1.jpg',
      msite: '/images/guangzhou/1_1.jpg'
    },
    jobs: [
      {
        id: 'guangzhou-job-1',
        title: '欧美女装买手',
        image: '/images/shanghai/10.jpg',
        regionType:2,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'guangzhou-job-1-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、负责欧美快时尚女装新品开款工作，在买手部门选款方向清晰时，能快速响应并开发适销对路的服装新款；',
              '2、有一定的服装选款和企划能力，能有效探索到新的爆款元素；',
              '3、收集时尚资讯和流行元素，并进行趋势分析，对产品选款提出开款指引；',
              '4、负责协助开发商品企划及商品定位，控制产品线的开发，对发生的问题及时解决，并不断优化产品。',
            ]
          },
          {
            id: 'guangzhou-job-1-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、跨境电商公司1-3年买手/产品开发岗位经验，了解供应链流程，有爆款开款能力；',
              '2、有常规品类、针织、牛仔等生产力的工厂/供应链资源优先；',
              '3、善于总结分析畅滞销品，了解客户需要，并应用到新品开发中；',
              '4、有供应商资源，并能独立开发、培养优质供应商的能力；',
              '5、应聘时有以往开款/企划作品更佳；',
              '6、服装设计专业优先考虑。',
            ]
          }
        ]
      },
      {
        id: 'guangzhou-job-2',
        title: '欧美大码女装、配饰买手',
        image: '/images/shanghai/10.jpg',
        regionType:2,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'guangzhou-job-2-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1、负责欧美快时尚大码女装、配饰新品开款工作，在买手部门选款方向清晰时，能快速响应并开发适销对路的新款；',
              '2、有一定的大码女装、配饰选款经验和企划能力，能有效探索到新的爆款元素；',
              '3、收集时尚资讯和流行元素，并进行趋势分析，对产品选款提出开款指引；',
              '4、负责协助开发商品企划及商品定位，控制产品线的开发，对发生的问题及时解决，并不断优化产品。',
            ]
          },
          {
            id: 'guangzhou-job-2-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、跨境电商公司1-3年买手/产品开发岗位经验，了解供应链流程，有爆款开款能力；',
              '2、有常规品类、针织、牛仔等生产力的工厂/供应链资源优先；',
              '3、善于总结分析畅滞销品，了解客户需要，并应用到新品开发中；',
              '4、有供应商资源，并能独立开发、培养优质供应商的能力；',
              '5、应聘时有以往开款/企划作品更佳；',
              '6、服装设计专业优先考虑。',
            ]
          }
        ]
      },
      {
        id: 'guangzhou-job-3',
        title: '服装仓库质检员（仓库QC）',
        image: '/images/shanghai/10.jpg',
        regionType:2,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'guangzhou-job-3-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1、按公司产品检查基准、抽样计划的要求对工厂到货进行质检，核对包装贴纸，水洗唛，主唛等标示信息的准确性，检查货品的尺寸，版型，工艺，面料等是否符合质量要求，对异常货品进行拍照并及时反馈货品质量情况；',
              '2、对工厂的质量评价及辅导工作；',
              '3、对异常货品的残损鉴定提出专业意见；',
              '4、在规定时间内完成工厂到货的质检，不影响仓库入库；',
            ]
          },
          {
            id: 'guangzhou-job-3-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、有较强的学习与理解能力；',
              '2、有工厂查货/质检经验者优先；',
              '3、肯吃苦耐劳，执行力强；',
              '4、态度严谨，认真，吃苦耐劳，质量意识强，能够承受工作中的压力和挑战；',
              '5、会使用基本电脑办公软件。',
            ]
          }
        ]
      },
    ]
  },
  {
    regionType:3,
    banner: {
      pc: '/images/shanghai/1.jpg',
      msite: '/images/shanghai/1_1.jpg'
    },
    jobs: [
      {
        id: 'nanjing-job-1',
        title: '英语客户维护专员',
        image: '/images/shanghai/8.jpg',
        regionType:3,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'nanjing-job-1-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1. 通过电子邮件/在线软件回复海外客户的咨询与问题，提供相关业务和流程的介绍。',
              '2. 受理客户的投诉，确保客户对产品和服务满意。',
              '3. 处理问题订单，通过邮件联系客户以保证订单正常发出。',
              '4. 产品及店铺评价定期处理和维护，保证店铺好评率。',
              '5. 总结整理客户问题，提供改善建议，提升客户满意度。',
            ]
          },
          {
            id: 'nanjing-job-1-dsc-2',
            title: '任职要求',
            descriptions: [
              '1. 大专或以上学历，CET-4以上，熟悉常用办公软件，熟练的英文读写能力。',
              '2. 责任心强，做事细心踏实，工作积极主动，有良好的英语听说读写能力，能够准确领会国外买家的意思，熟练的运用英语回复国外买家的邮件，灵活解答客户的问题。',
              '3. 掌握客户服务基本技巧,有良好的客户服务意识、服务态度、判断能力，处理网络纠纷及解决纠纷的能力。',
              '4. 具有团队协作精神，强烈的责任心和进取心，能承受工作压力。',
              '5. 有跨境电商行业的优先；高底薪！',
            ]
          }
        ]
      },
      {
        id: 'nanjing-job-2',
        title: '德语客户维护专员',
        image: '/images/shanghai/8.jpg',
        regionType:3,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'nanjing-job-2-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1. 熟练运用德语听说读写译，主要通过邮件、在线与客户及时沟通，高效处理各类订单问题及客户疑问；',
              '2. 良好的逻辑思维能力和沟通技巧，能够有效清晰的根据客户需求解决问题，维护客户满意度同时减少损失。',
              '3. 善于发现和总结问题，并且提供有效解决方案，定期整理FAQ。',
              '4. 具备一定的德语理解能力，适时编辑、翻译各类文章内容。',
              '5. 积极上进，注重团队合作，定时完成上级安排的各项任务。',
              '6. 为有需要的部门提供德语翻译支持；',
              '7. 有电子商务网站客服经验者优先。',
            ]
          },
          {
            id: 'nanjing-job-2-dsc-2',
            title: '任职要求',
            descriptions: [
              '1. 统招本科以上学历，优秀的德语水平，专四以上；',
              '2. 责任心强，做事细心踏实，工作积极主动，熟练运用德语准确领会买家的意思并回复买家的邮件，灵活解答客户的问题。',
              '3. 掌握客户服务基本技巧,有良好的客户服务意识、服务态度、判断能力，处理网络纠纷及解决纠纷的能力。',
              '4. 具有团队协作精神，强烈的责任心和进取心，能承受工作压力。',
              '5. 有电子商务网站德语客服经验者优先，应届生若感兴趣可定向培养。',
            ]
          }
        ]
      },
      {
        id: 'nanjing-job-3',
        title: '葡萄牙语客户维护专员',
        image: '/images/shanghai/8.jpg',
        regionType:3,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'nanjing-job-3-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1. 有良好的葡萄牙语听说读写能力，通过电子邮件/在线软件回复海外客户的咨询与问题，提供相关业务和流程的介绍。',
              '2. 受理客户的投诉，确保客户对产品和服务满意。',
              '3. 处理问题订单，通过邮件联系客户以保证订单正常发出。',
              '4. 产品及店铺评价定期处理和维护，保证店铺好评率。',
              '5. 总结整理客户问题，提供改善建议，提升客户满意度。',
              '6. 为有需要的部门提供葡萄牙语翻译支持。',
            ]
          },
          {
            id: 'nanjing-job-3-dsc-2',
            title: '任职要求',
            descriptions: [
              '1. 本科及以上学历，葡萄牙语相关专业，葡萄牙语读写能力，优秀应届生优先；',
              '2. 责任心强，做事细心踏实，工作积极主动，有良好葡萄牙语听说读写能力，熟练的运用葡萄牙语准确领会国外买家的意思并回复国外买家的邮件，灵活解答客户的问题。',
              '3. 掌握客户服务基本技巧,有良好的客户服务意识、服务态度、判断能力，处理网络纠纷及解决纠纷的能力。',
              '4. 具有团队协作精神，强烈的责任心和进取心，能承受工作压力。',
              '5. 有电子商务网站葡萄牙语客服经验者优先，应届生若感兴趣可定向培养。',
            ]
          }
        ]
      },
      {
        id: 'nanjing-job-4',
        title: '法语客户维护专员',
        image: '/images/shanghai/8.jpg',
        regionType:3,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'nanjing-job-4-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1. 熟练运用法语听说读写译，主要通过邮件、在线与客户及时沟通，高效处理各类订单问题及客户疑问；',
              '2. 良好的逻辑思维能力和沟通技巧，能够有效清晰的根据客户需求解决问题，维护客户满意度同时减少损失。',
              '3. 善于发现和总结问题，并且提供有效解决方案，定期整理FAQ。',
              '4. 具备一定的法语理解能力，适时编辑、翻译各类文章内容。',
              '5. 积极上进，注重团队合作，定时完成上级安排的各项任务。',
              '6. 为有需要的部门提供法语翻译支持；',
              '7. 有电子商务网站客服经验者优先。',
            ]
          },
          {
            id: 'nanjing-job-4-dsc-2',
            title: '任职要求',
            descriptions: [
              '1. 统招本科以上学历，优秀的法语水平，专四以上；',
              '2. 责任心强，做事细心踏实，工作积极主动，熟练运用法语准确领会买家的意思并回复买家的邮件，灵活解答客户的问题。',
              '3. 掌握客户服务基本技巧,有良好的客户服务意识、服务态度、判断能力，处理网络纠纷及解决纠纷的能力。',
              '4. 具有团队协作精神，强烈的责任心和进取心，能承受工作压力。',
              '5. 有电子商务网站法语客服经验者优先，应届生若感兴趣可定向培养。',
            ]
          }
        ]
      },
      {
        id: 'nanjing-job-5',
        title: '西班牙语客户维护专员',
        image: '/images/shanghai/8.jpg',
        regionType:3,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'nanjing-job-5-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1. 有良好的西班牙语听说读写能力，通过电子邮件/在线软件回复海外客户的咨询与问题，提供相关业务和流程的介绍。',
              '2. 受理客户的投诉，确保客户对产品和服务满意。',
              '3. 处理问题订单，通过邮件联系客户以保证订单正常发出。',
              '4. 产品及店铺评价定期处理和维护，保证店铺好评率。',
              '5. 总结整理客户问题，提供改善建议，提升客户满意度。',
              '6. 为有需要的部门提供西班牙语翻译支持。',
            ]
          },
          {
            id: 'nanjing-job-5-dsc-2',
            title: '任职要求',
            descriptions: [
              '1. 本科及以上学历，西班牙语相关专业，西班牙语读写能力，优秀应届生优先；',
              '2. 责任心强，做事细心踏实，工作积极主动，有良好西班牙语听说读写能力，熟练的运用西班牙语准确领会国外买家的意思并回复国外买家的邮件，灵活解答客户的问题。',
              '3. 掌握客户服务基本技巧,有良好的客户服务意识、服务态度、判断能力，处理网络纠纷及解决纠纷的能力。',
              '4. 具有团队协作精神，强烈的责任心和进取心，能承受工作压力。',
              '5. 有电子商务网站西班牙语客服经验者优先，应届生若感兴趣可定向培养。',
            ]
          }
        ]
      },
      {
        id: 'nanjing-job-6',
        title: '意大利语客户维护专员',
        image: '/images/shanghai/8.jpg',
        regionType:3,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'nanjing-job-6-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1. 有良好的意大利语听说读写能力，通过电子邮件/在线软件回复海外客户的咨询与问题，提供相关业务和流程的介绍。',
              '2. 受理客户的投诉，确保客户对产品和服务满意。',
              '3. 处理问题订单，通过邮件联系客户以保证订单正常发出。',
              '4. 产品及店铺评价定期处理和维护，保证店铺好评率。',
              '5. 总结整理客户问题，提供改善建议，提升客户满意度。',
              '6. 为有需要的部门提供意大利语翻译支持。',
            ]
          },
          {
            id: 'nanjing-job-6-dsc-2',
            title: '任职要求',
            descriptions: [
              '1. 本科及以上学历，意大利语相关专业，意大利语读写能力，优秀应届生优先；',
              '2. 责任心强，做事细心踏实，工作积极主动，有良好意大利语听说读写能力，熟练的运用意大利语准确领会国外买家的意思并回复国外买家的邮件，灵活解答客户的问题。',
              '3. 掌握客户服务基本技巧,有良好的客户服务意识、服务态度、判断能力，处理网络纠纷及解决纠纷的能力。',
              '4. 具有团队协作精神，强烈的责任心和进取心，能承受工作压力。',
              '5. 有电子商务网站意大利语客服经验者优先，应届生若感兴趣可定向培养。',
            ]
          }
        ]
      },
      {
        id: 'nanjing-job-7',
        title: '亚马逊运营专员',
        image: '/images/shanghai/8.jpg',
        regionType:3,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'nanjing-job-7-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1、【独立运营】独立负责在Amazon平台上产品运营相关工作，完成销售目标；',
              '2、【编辑上架】定期上架新品，维护和优化listing页面，调整listing状态；',
              '3、【营销推广】制定产品营销推广活动，并对营销活动的实施进行调研、分析、预测、推广手段设计及效果评估等；',
              '4、【维护运营】分析销售、库存、退货等数据及时调整销售计划，制定处理方案；',
              '5、【库存管理】预测销售趋势，制定备货计划，安排发FBA，维持库存与销售之间的平衡。',
            ]
          },
          {
            id: 'nanjing-job-7-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、经验丰富：亚马逊平台有1年以上经验者，有服装、小商品等产品销售经验者优先考虑。',
              '2、自我驱动：做事有目标、有计划，不满足于现状，乐于接受挑战；',
              '3、灵活果敢：敏锐的市场分析能力，思维灵活，能独立解决各种业务问题；',
              '4、数据敏感：对数据敏感，通过分析数据制定营销策略；',
              '5、通关技能：英语读写熟练（CET6或TEM4），熟悉EXCEL等办公软件操作。',
            ]
          }
        ]
      },
      {
        id: 'nanjing-job-8',
        title: '速卖通运营专员',
        image: '/images/shanghai/8.jpg',
        regionType:3,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'nanjing-job-8-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1、【独立运营】独立负责在速卖通平台上产品运营相关工作，完成销售目标；',
              '2、【编辑上架】熟悉产品并控制产品风险，按速卖通平台规则和公司要求编辑产品信息、上传产品、实施新品推广计划；',
              '3、【营销推广】对产品的价格进行调研、分析以及做出调价安排，拟定合理的产品上架计划，提升产品市场占有率；',
              '4、【维护运营】研究分析行业市场，速卖通各平台对手，根据市场及店铺定位处理定价和调价工作；',
              '5、【熟悉政策】速卖通运营推广经验,了解速卖通政策变化。',
            ]
          },
          {
            id: 'nanjing-job-8-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、经验丰富：速卖通平台有1年以上经验者，有服装、小商品等产品销售经验者优先考虑。',
              '2、自我驱动：做事有目标、有计划，不满足于现状，乐于接受挑战；',
              '3、灵活果敢：敏锐的市场分析能力，思维灵活，能独立解决各种业务问题；',
              '4、数据敏感：对数据敏感，通过分析数据制定营销策略；',
              '5、通关技能：英语读写熟练（CET6或TEM4），熟悉EXCEL等办公软件操作。',
            ]
          }
        ]
      }
    ]
  },
  {
    regionType:4,
    banner: {
      pc: '/images/shanghai/1.jpg',
      msite: '/images/shanghai/1_1.jpg'
    },
    jobs: [
      {
        id: 'zhaoqing-job-1',
        title: '服装搭配摄影师',
        image: '/images/shanghai/11.jpg',
        regionType:4,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'zhaoqing-job-1-dsc-1',
            title: '岗位职能',
            descriptions: [
              '1、根据产品给出的风格定位拍摄衣服；',
              '2、制定拍摄方案，给出场景选择、风格定位、拍摄参考、效果预估等设想；',
              '3、摄完成相关服装拍摄，把控图片输出效果。',
            ]
          },
          {
            id: 'zhaoqing-job-1-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、一年以上摄影经验；',
              '2、可独立置景选景以完成拍摄；',
              '3、会使用相关修图和视频剪辑软件优先；',
              '4、具有丰富的产品展示、组合搭配；',
              '5、实时关注国外拍摄/时尚行业的发展趋势，有跨境电商欧美风格经验者优先考虑。',
            ]
          }
        ]
      }
    ]
  },
];

// 校园招聘
export const data2 = [
  {
    banner: {
      pc: '/images/shanghai/1.jpg',
      msite: '/images/shanghai/1_1.jpg'
    },
    regionType:1,
    jobs: [
      {
        id: 'shanghai-job-1',
        title: '德语网站运营',
        image: '/images/shanghai/8.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-1-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、协助上级完成电商平台的整体规划、营销、推广、客户关系管理等系统经营性工作；',
              '2、负责策划电商平台促销活动、上架、推广等日常运作；',
              '3、关注和分析运营数据，制定内容和数据运营计划，持续改善核心数据指标；',
              '4、负责收集市场和行业信息，商品分析，为公司开发提供相关信息；',
              '5、负责跨境电商平台女装、鞋包配饰、美妆等相关品类运营及工作。'
            ]
          },
          {
            id: 'shanghai-job-1-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、本科及以上学历，德语专业背景，服装/数学/统计学/统计分析等相关专业优先；',
              '2、具备一定电商运营经验优先，熟悉电商运营模式及各项流程优先；',
              '3、熟悉主流电商平台的运营方法，具备系统的数据解读能力，前瞻性和统筹规划能力，对市场有敏锐观察和判断能力。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-2',
        title: '广告优化师',
        image: '/images/shanghai/10.jpg',
        regionType:1,
        time:"2022-06-06",
        isHot:false,
        descriptions: [
          {
            id: 'shanghai-job-2-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、管理和优化Facebook/Google推广账户；',
              '2、根据产品推广需求，制定科学合理的推广方案，并能够顺利实施；',
              '3、通过具体推广数据，分析市场需求，制定优化方案， 以严谨有效的广告投放分析思路进行管理优化；',
              '4、关注并学习研究有助于广告投放的相关功能或产品，评估并选择适合于公司产品推广的新功能或产品，开展投放工作。',
            ]
          },
          {
            id: 'shanghai-job-2-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、统招211、985本科及以上学历，CET-6及以上，英语/市场营销/电子商务/广告等相关专业；',
              '2、有电商相关实习经验，市场敏与锐度良好、数据敏感度高，有跨境电商广告投放或优化经验者优先；',
              '3、有良好的逻辑分析能力、快速学习能力和团队协作精神；',
              '4、热爱互联网营销，对工作有激情，有很好的抗压能力和问题解决能力。'
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-3',
        title: 'Java开发工程师',
        image: '/images/shanghai/4.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-3-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、按照工作计划和进度完成系统模块的开发任务，保证开发的质量；',
              '2、熟悉Java语言和面向对象设计方法，熟练掌握JSP、Servlet、JDBC等技术，熟悉html，javascript；',
              '3、能够使用Spring、Hibernate、MyBatis等主流框架进行开发；',
              '4、熟悉各种设计模式并能在开发过程中熟练应用；',
              '5、熟悉关系数据库，熟练掌握SQL语言；',
              '6、能理解业务逻辑，对业务模型进行设计，编写出规范的设计文档；',
              '7、沟通需求和开发中遇到的相关问题。',
            ]
          },
          {
            id: 'shanghai-job-3-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、统招211、985本科及以上学历，计算机/通信工程等相关专业；',
              '2、热爱互联网，喜欢从事IT技术类工作；',
              '3、学习能力强，工作热情高，富有责任感，在高级工程师的指导下完成工作内容。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-4',
        title: '广告素材设计',
        image: '/images/shanghai/9.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-4-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、负责公司产品在海外推广时使用的图片、视频广告素材的制作和优化；',
              '2、搜集并研究竞品内容与广告，提炼总结创意特征点，挖掘创意思路与素材；',
              '3、配合广告同事制作相应要求广告素材；',
              '4、公司布置的其他工作等。',
            ]
          },
          {
            id: 'shanghai-job-4-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、统招大专及以上学历，美术、平面设计相关专业；',
              '2、有电商行业美工实习经验、文案经验者优先；',
              '3、会使用ps、pr等软件，会视频剪辑者优先；',
              '4、具备良好的审美能力，对色彩、构图等有较强的把握能力。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-5',
        title: '视频剪辑',
        image: '/images/shanghai/9.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-5-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、根据公司项目要求独立完成后期剪辑工作，包括视频剪辑、基本特效、音乐合成等；',
              '2、制作在海外推广所需的视频广告，动画广告；',
              '3、熟悉Tiktok、instagram、youtube 等社交平台视频剪辑思路，与创意团队一起，策划广告的创意，设计；',
              '4、理解项目脚本，运用镜头语言，包括剪辑、调色、声音处理、字幕特效合成等；',
              '5、协调与沟通视频制作过程中的各相关环节，及时反馈剪辑过程中的问题，完成制作全过程，保证成片质量；',
              '6、根据需要，对各种视频、音频内容进行二次创作。',
            ]
          },
          {
            id: 'shanghai-job-5-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、本科及以上学历，有视频剪辑实习经验，会摄像优先；',
              '2、掌握PS、AI、PR、AE等剪辑软件，可以独立调整视频格式和剪辑各类视频；',
              '3、工作认真细致，具有较强的团队协作能力，沟通能力较强；',
              '4、对移动互联网营销特点及趋势有一定了解；',
              '5、有扎实的美术功底和自主设计的理念，具备良好的审美观。',
            ]
          }
        ]
      },
      {
        id: 'shanghai-job-6',
        title: '品类企划助理',
        image: '/images/shanghai/7.jpg',
        regionType:1,
        time:"2022-06-06",
        descriptions: [
          {
            id: 'shanghai-job-6-dsc-1',
            title: '岗位职责',
            descriptions: [
              '1、进行流行趋势搜集、时尚咨询整理和竞争对手分析（品牌款式、颜色、价格等），进行分析形成产品分析报告，给予产品开发建议；',
              '2、结合市场和客户需求，善于利用工具对企划数据进行多维度数据分析，并针对市场的变化制定和调整后续产品开发和商品运营计划；',
              '3、配合完成相应女装品类商品的企划，包括但不限于款式数量、产品品类比例、价格设定、尝试款设定等多项内容，并适时提出意见和建议，把商品规划落实及进度跟进；',
              '4、负责企划方案的跟踪、督导，统筹协调管理，协调商品部门，使产品达到企划预期，并进行调整，达成公司销售目标。',
            ]
          },
          {
            id: 'shanghai-job-6-dsc-2',
            title: '任职要求',
            descriptions: [
              '1、本科及以上学历，英语6级及以上，有海外留学或者英语专业毕业生优先；',
              '2、关注时尚，有一定的敏锐度， 能敏锐地把握国外服装潮流的市场动态和趋势；有自己的想法并善于运用资源落地到实践；',
              '3、具有较高数据敏感度，善于利用工具进行多维度数据分析，熟练操作Excel、Word、PPT等办公软件产出报告；',
              '4、优秀的市场敏锐度和分析能力，善于协调各部门之间沟通；积极主动、乐于接受挑战，良好的团队协作精神和责任意识。',
            ]
          }
        ]
      }
    ]
  },
  {
    banner: {
      pc: '/images/guangzhou/1.jpg',
      msite: '/images/guangzhou/1_1.jpg'
    },
    regionType:2,
    jobs: [
    ]
  },
  {
    banner: {
      pc: '/images/shanghai/1.jpg',
      msite: '/images/shanghai/1_1.jpg'
    },
    regionType:3,
    jobs: [
    ]
  },
  {
    banner: {
      pc: '/images/shanghai/1.jpg',
      msite: '/images/shanghai/1_1.jpg'
    },
    regionType:4,
    jobs: [
    ]
  }
];

export const geekoHonor = {
  title:"Geeko荣誉",
  descrptions:[
    "1、PAYPAL BEST移动体验奖",
    "2、谷歌xBrandZ中国最具潜力全球化品牌15强"
  ]
};


export const getSource = (type) => {
  if(type === '1'){
    
    return params => {
      return Promise.resolve(data[params.detail])
    }
  }
}